import React, { useEffect, useState } from "react"

import {
  Typography,
  Grid,
  Divider,
  Paper,
  useMediaQuery,
} from "@material-ui/core"
import TocIcon from "@material-ui/icons/Toc"
import { makeStyles, useTheme } from "@material-ui/core/styles"


import Layout from "../components/layout"
import SEO from "../components/seo"

import Loading from "../components/loading"
import Bread from "../components/bread"
import Description from "../components/description"
import ACMInfo from "../components/acminfo"

import "../components/title.css"

import loadable from '@loadable/component'
// import PaperList from "../components/list"
const PaperList = loadable(() => import(/* webpackPrefetch: true */ "../components/list"),{
  fallback: <div style={{minHeight: '75vh'}}/>
})
// import TOC from "../components/sessiontoc"
const TOC = loadable(() => import(/* webpackPrefetch: true */ '../components/sessiontoc'))
// import SimpleToc from '../components/simpletoc'
const SimpleToc = loadable(() => import(/* webpackPrefetch: true */ '../components/simpletoc'))
// import SearchBar from "../components/search"
const SearchBar = loadable(() => import(/* webpackPrefetch: true */ "../components/search"))

const blue = "#1a58a1"
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(4),
    background: "white",
    border: "solid 0.2rem #9992",
  },
  bringToLast: {
    [theme.breakpoints.down("sm")]: {
      order: 5,
    },
  },
  scroll: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
    listStyle: "none",
    paddingLeft: 0,
    paddingBottom: "10px",
    "& > *": {
      marginBottom: theme.spacing(0.3),
      borderLeft: "solid 2px #999",
      paddingLeft: theme.spacing(1),
    },
    "& > li": {
      transition: "background-color 0.2s",
    },
    "& > li:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    "& > li > a": {
      color: "#666",
      textDecoration: "none",
      display: "block",
      position: "relative",
      // marginBottom: '-5px',
      paddingBottom: "5px",
      // marginTop: '-5px',
      paddingTop: "5px",
    },
    "& > li > a:hover": {
      color: blue,
    },
  },
  currentScroll: {
    borderLeft: `solid 4px ${blue}`,
    fontWeight: "bold",
  },
  stick: {
    marginTop: theme.spacing(2),
    position: "sticky",
    top: 80,
    padding: theme.spacing(2),
  },
  tocBlock: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  tocTitle: {
    paddingLeft: theme.spacing(1),
    fontWeight: "bolder",
    fontFamily: ["Merriweather", "Times New Roman", "serif"],
  },
}))

const Proceedings = ({initType='Proceedings'}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [dataset, setData] = useState(false)
  const [filterWord, setFilterWord] = useState()
  const [dataError, setDataError] = useState(false)
  const [pageType, setPageType] = useState(initType)
  const [listOK, setListOK] = useState(false)
  const isBig = useMediaQuery(theme.breakpoints.up("sm"))
  const switchPage = () => {
    setData(null)
    setPageType(null)
    setDataError(null)
    setListOK(false)
    // setFilterWord('')
  }
  useEffect(() => {
    let href = window.location.pathname.split("/")
    href = href[href.length - 2]
    switch (href) {
      case "companion_proceedings":
        if (href !== "Companion Proceedings") {
          setPageType("Companion Proceedings")
        }
        break
      case 'proceedings':
        if (href !== "Proceedings") {
          setPageType("Proceedings")
        }
        break
      default:
        break
    }
  }, [pageType])
  useEffect(() => {
    if (!dataset) {
      // console.log(window.location.pathname.split('/'))
      fetch("./toc.json")
        .then(res => res.json())
        .catch(e => {
          console.log(e)
          setDataError(true)
        })
        .then(result => {
          setData(result)
        })
        .catch(e => {
          console.log(e)
          setDataError(true)
        })
    }
  }, [dataset])

  return (
    <Layout title={pageType}>
      <SEO title={pageType || "WWW 2020 INFO"} />
      <Bread where={pageType} handleFlushPage={switchPage} />

      <Grid container spacing={3} justify="center" className={classes.root}>
        <Description word={filterWord} pageType={pageType} />
        <Grid item xs={12} md={8} lg={8} className={classes.bringToLast}>
          {dataset ? (
            <PaperList
              dataset={dataset}
              word={filterWord}
              dataType={pageType}
              doneCallback={setListOK}
            />
          ) : (
              <Loading error={dataError} />
            )}
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <ACMInfo />
          {dataset && (
            <Paper className={classes.stick} elevation={4}>
              <SearchBar submit={setFilterWord} />
              <div className={classes.tocBlock}>
                <TocIcon />
                <Typography
                  variant="h6"
                  component="span"
                  className={classes.tocTitle}
                >
                  Session List
                </Typography>
              </div>
              <Divider />
              {
                isBig && listOK ?
                  <TOC dataset={dataset} clss={classes} />
                  :
                  <SimpleToc sessions={dataset.map(({ session }) => session)} clss={classes.scroll} />
              }

            </Paper>
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Proceedings
