import React from "react"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { Button } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
const red = "#e73d3f"
const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(7),
  },
  switchPageBtn: {
    marginLeft: "auto",
    color: red,
    borderColor: red,
    // display: "flex",
  },
}))

const others = {
  Proceedings: ["/companion_proceedings/", "Comp. Proc."],
  "Companion Proceedings": ["/proceedings/", "Proc."],
}

const setNewPage = (flushPage, url) => e => {
  e.preventDefault()
  window.history.pushState({}, "", url)
  flushPage()
}

export default function SimpleBreadcrumbs({ where, handleFlushPage }) {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          WWW '20 Info
        </Link>
        <strong>{where || "Proceedings"}</strong>
      </Breadcrumbs>
      {where ? (
        <Button
          className={classes.switchPageBtn}
          variant="outlined"
          // color="secondary"
          startIcon={<ArrowForwardIcon />}
          href={others[where][0]}
          children={others[where][1]}
          size="small"
          onClick={setNewPage(handleFlushPage, others[where][0])}
        />
      ) : (
        <Button
          disabled
          className={classes.switchPageBtn}
          variant="outlined"
          startIcon={<ArrowForwardIcon />}
          children="SWITCH GOTO"
          size="small"
        />
      )}
    </div>
  )
}
