import React from "react"
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Link,
} from "@material-ui/core"
// import { Close } from '@material-ui/icons'
import ACMLogo from "./acmdllogo"
import W20Div from "./w20divider"

import { makeStyles, useTheme } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  card: {
    padding: theme.spacing(1.5),
    position: "relative",
  },
  // close: {
  //   position: 'absolute',
  //   right: theme.spacing(1),
  // },
  action: {
    justifyContent: "space-evenly",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  link: {
    display: "block",
    padding: theme.spacing(1),
    color: "#1a58a1",
    fontWeight: "bolder",
    transition: "border-color 0.2s",
    borderBottom: "solid 2px rgba(0,0,0,0)",
    "&:hover": {
      borderBottomColor: "#1a58a1",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  content: {
    "& > p": {
      paddingBottom: theme.spacing(1),
    },
    "& > hr": {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(0.8),
    },
  },
}))

const ACMInfo = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  // const [close, setClose] = useState(false)
  // const md = useMediaQuery(theme.breakpoints.up("md"))
  // const handleClose = e => {
  //   e.preventDefault()
  //   setClose(!close)
  // }
  return (
    // <Collapse appear={false} in={!close} unmountOnExit={true}>
    <Card elevation={3} className={classes.card}>
      {/* <IconButton
        className={classes.close}
        aria-label="Close Info"
        component="span"
        children={<Close />}
        onClick={handleClose}
      /> */}
      <CardContent className={classes.content}>
        <ACMLogo />
        <W20Div />
        <Typography variant="body1">
          Get more information about these proceedings at ACM Digital Library.
          Full citation are also available.
        </Typography>
        <Typography variant="body1">
          If you encounter any issue to access papers in ACM DL platform, please
          use the link provide in this page first. If the problem continues, use
          the legacy page instead.
        </Typography>
      </CardContent>
      <CardActions className={classes.action}>
        <Link
          children="ACM Proceedings"
          href="https://dl.acm.org/doi/proceedings/10.1145/3366423"
          title="ACM DL www 2020 Proceedings"
          target="_blank"
          rel="noopener"
          underline="none"
          className={classes.link}
        />
        <Link
          children="ACM Companion Proceedings"
          href="https://dl.acm.org/doi/proceedings/10.1145/3366424"
          title="ACM DL www 2020 Companion Proceedings"
          target="_blank"
          rel="noopener"
          underline="none"
          className={classes.link}
        />
      </CardActions>
    </Card>
    // </Collapse>
  )
}

export default ACMInfo
