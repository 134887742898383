import React, { useState, useEffect } from "react"

import {
  Typography,
  CircularProgress,
  Box,
  Divider,
  Link,
} from "@material-ui/core"

import WarningRoundedIcon from "@material-ui/icons/WarningRounded"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  root: {
    margin: '20vh auto 45vh auto',
    display: "flex",
    // marginLeft: "auto",
    // marginRight: "auto",
    // padding: theme.spacing(5),
    justifyContent: "center",
    alignItems: "center",
    // "& > *": {
    //   margin: theme.spacing(1),
    // },
  },
  loadingDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // padding: theme.spacing(1),
  }
}))

const Loading = ({ error }) => {
  const classes = useStyles()
  const [tooLong, setTooLong] = useState(false)
  useEffect(() => {
    setTimeout(e => {
      setTooLong(true)
    }, 800)
  }, [])
  return tooLong ? (
    <Box className={classes.root}>
      {error ? <WarningRoundedIcon color="error" /> : <CircularProgress />}
      <Divider orientation="vertical" flexItem={true} className={classes.loadingDivider} />
      <Typography variant="body1" color="textSecondary">
        {error ? (
          <Typography component="span">
            Unable to fetch data. Please use{" "}
            <Link href="./legacy.html" children="legacy website" />.
          </Typography>
        ) : (
            "Content is loading..."
          )}
      </Typography>
    </Box>
  ) : (
      <div className={classes.root}></div>
    )
}

export default Loading
