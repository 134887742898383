import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
}))

const ACMLogo = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "acmdl.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // console.log(data)
  return (
    <Box>
      <Link
        underline="none"
        title="ACM Digital Library"
        href="https://dl.acm.org/"
        target="_blank"
        rel="noopener"
        className={classes.root}
      >
        {!data?.placeholderImage?.childImageSharp?.fixed ? (
          <></>
        ) : (
          <Img fixed={data.placeholderImage.childImageSharp.fixed} />
        )}
        <Typography component="span" variant="subtitle2" color="textSecondary">
          ACM Digital Library
        </Typography>
      </Link>
    </Box>
  )
}

export default ACMLogo
