import React from "react"
import { Grid, Box, Typography, Divider } from "@material-ui/core"

const Description = ({ word, pageType }) => {
  return (
    <Grid item xs={12}>
      <Box mt={1} pb={2} fontStyle="italic">
        <Typography variant="h4" component="h1" color="textPrimary">
          <strong>{pageType || "Proceedings"}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          The web conference 2020 proceedings and companion proceedings.
        </Typography>
      </Box>
      {/* <W20Div /> */}
      <Divider />
      {word ? (
        <Typography variant="overline" component="span">
          <Box position="absolute" color="#1a58a1" right={45}>
            Search Paper: <strong>{word}</strong>
          </Box>
        </Typography>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default Description
